<template>
    <div class="panel">
        <div class="panel-heading">
            <div class="panel-heading-text">{{text.preview_advert_formats}} <span>{{text.preview_advert_formats_note}}</span></div>
            <div class="panel-heading-cta">
                <a href="#" title="" class="btn btn-tertiary btn-small btn-nospace" v-if="showRefreshButton" @click="triggerSave($event)" @keyup.enter="triggerSave($event)">{{text.save_and_update_preview}}</a>
            </div>
        </div>
        <div class="panel-content panel-content-grid" v-if="previewFormats.length > 0">
            <section class="panel-content-grid-item" v-for="row in previewFormats" :key="row.id">
                <div class="panel-content-grid-item-inner">
                    <h1 class="panel-content-grid-item-title">{{row.name}}</h1>
                    <div class="panel-content-grid-item-image image image-border">
                        <img v-auth-src="buildPreviewImagePath(advertID, row.key)" :alt="row.name | cleanString" />
                    </div>
                </div>
            </section>
        </div>
        <div class="panel-content" v-else>
            <p class="pt-4 pb-4" >{{text.no_preview_formats}}</p>
        </div>
    </div>
</template>

<script>
    import lang from '@/shared/lang';
    import {log} from '@/shared/utils';

    import {advertExportActionsMixin} from "@/views/mixins/advertExportActions";
    import {formatContentMixin} from "@/views/mixins/formatContent";

    export default {
        name: "AdvertPreview",
        mixins: [formatContentMixin, advertExportActionsMixin],
        props: {
            publishFormats: {
                type: Array,
            },
            showRefreshButton: {
                type: Boolean,
                default: false
            },
            advertID: {
                type: [Number, String],
            }
        },
        computed: {
            text() {
                return lang;
            },
        },
        methods: {
            triggerSave(e) {
                e.preventDefault();
                log(["Trigger preview save"]);
                this.$emit('previewsave');
            }
        },
    }
</script>