<template>
  <div>
    <div class="panel panel-frameless">
      <div class="panel-content panel-content-grid" v-if="publishFormats.length > 0">
        <section class="panel-content-grid-item" v-for="row in publishFormats" :key="row.id">
          <div class="panel-content-grid-item-inner">
            <h1 class="panel-content-grid-item-title">{{ row.name }}</h1>
            <a href="#" :title="text.download" class="btn btn-secondary btn-small"
               @click="onDownloadPublishFormat($event, id, row.key)"
               @keyup.enter="onDownloadPublishFormat($event, id, row.key)">{{ text.download }}</a>
            <!-- TODO: ADD CONDITION TO TYPE OF FORMAT -->
            <div class="panel-content-grid-item-image image image-border" v-if="row.hasPreview">
              <img v-auth-src="buildPreviewImagePath(getAdvertID(), row.key)" :alt="row.name | cleanString"/>
            </div>
          </div>
        </section>
      </div>
    </div>

    <div class="button-group clearfix">
      <div class="button-group-left">
        <router-link :to="{ name: addSectionPrefix('AdvertsEdit'), params : { 'id' : getAdvertID() } }" :title="text.back_previous"
                     class="btn btn-frameless">{{ text.back_previous }}
        </router-link>
      </div>
      <div class="button-group-right"></div>
    </div>
  </div>
</template>

<script>
    import {advertExportActionsMixin} from "@/views/mixins/advertExportActions";
    import {advertActionsMixin} from "@/views/mixins/advertActions";
    import {formatContentMixin} from "@/views/mixins/formatContent";

    import {log} from "@/shared/utils";
    import lang from '@/shared/lang';

    export default {
        name: 'AdvertsExport',
        mixins: [formatContentMixin, advertActionsMixin, advertExportActionsMixin],
        computed: {
            text() {
                return lang;
            },
            id() {
                return this.$route.params.id;
            },
            publishFormats(){
                return this.getAdvertPublishFormats();
            }
        },
        methods: {
            async initAdvertExport() {
                log(["Init advert export", this.id]);

                log(["Mounted with existing advert", this.id]);
                await this.loadAdvertByID(this.id);
            }
        },
        async mounted() {
            await this.initAdvertExport();
        },
    }
</script>
