<template>
    <router-link :to="{ name: routeName, params: routeParams }" :title="text.select + ' ' + linkName">{{text.select}}</router-link>
</template>

<script>
    import lang from "@/shared/lang";

    export default {
        name: "SelectAction",
        props: {
            routeName: {
                type: String
            },
            routeParams: {
                type: Object
            },
            linkName: {
                type: String
            }
        },
        computed: {
            text() {
                return lang;
            },
        },
    }
</script>