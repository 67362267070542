<template>
    <div>
        <AdvertPreview :publishFormats="getAdvertPublishFormats()" :advertID="getAdvertID()" v-if="advertData.published" />

        <div class="columns">
            <div class="content">
                <Form v-model="advertFormData" ref="advertForm" />
            </div>
        </div>

        <div class="button-group clearfix">
            <div class="button-group-left">
                <router-link :to="{ name: addSectionPrefix('AdvertsList') }" :title="text.back_previous" class="btn btn-frameless">{{text.back_previous}}</router-link>
            </div>
            <div class="button-group-right">
                <CopyAction :linkName="advertName" @actionCalled="copyAdvert(id, addSectionPrefix('AdvertsEdit'))" :classString="'btn btn-tertiary btn-smyspace'" />
                <router-link :to="{ name: addSectionPrefix('AdvertsEdit'), params : { id : id } }" :title="text.edit + ' ' + advertName" class="btn btn-secondary btn-smyspace">{{text.edit}}</router-link>
                <router-link :to="{ name: addSectionPrefix('AdvertsExport'), params : { id : id } }" :title="text.export + ' ' + advertName" class="btn btn-primary" v-if="advertData.published">{{text.export}}</router-link>
            </div>
        </div>
    </div>
</template>

<script>
    import lang from '@/shared/lang';
    import {log, convertDateForInputValue} from '@/shared/utils';

    import {advertActionsMixin} from "@/views/mixins/advertActions";
    import {formWrapperMixin} from "@/views/mixins/formwrapper";

    import Form from "@/components/form/form";
    import CopyAction from "@/components/actionlinks/copy";
    import AdvertPreview from "@/components/advertpreview";
    import {mapActions, mapState} from "vuex";

    export default {
        name: 'AdvertsView',
        components: {Form, CopyAction, AdvertPreview},
        mixins: [advertActionsMixin, formWrapperMixin],
        data() {
            return {
                advertFormData: [
                    {
                        id: 100,
                        type: 'text',
                        name: 'name',
                        label: 'Name',
                        placeholder: "",
                        tooltip: "",
                        value: "",
                        readonly: true,
                        disabled: true,
                    },
                    {
                        id: 200,
                        type: 'select',
                        name: `businessUnitID`,
                        label: 'Publishing departments',
                        placeholder: "",
                        tooltip: "",
                        value: "",
                        options: [],
                        readonly: true,
                        disabled: true,
                    },
                    {
                        id: 301,
                        type: 'date',
                        name: `liveDate`,
                        label: 'Live date',
                        placeholder: "",
                        tooltip: "",
                        value: "",
                        options: [],
                        readonly: true,
                        disabled: true,
                        /*classString : "thirds",*/
                    },
                    {
                        id: 302,
                        type: 'date',
                        name: `expiryDate`,
                        label: 'Expiry date',
                        placeholder: "",
                        tooltip: "",
                        value: "",
                        options: [],
                        readonly: true,
                        disabled: true,
                        /*classString : "thirds",*/
                    },
                    {
                        id: 303,
                        type: 'checkbox',
                        name: `isPrivate`,
                        label: 'Is private',
                        placeholder: "",
                        tooltip: "",
                        value: false,
                        options: [],
                        readonly: true,
                        disabled: true,
                        /*classString : "thirds",*/
                    },
                ],
            }
        },
        computed: {
            ...mapState(['pageTitle']), // imports from store
            text() {
                return lang;
            },
            id() {
                return this.$route.params.id ;
            },
            advertName() {
                return this.getAdvertName();
            },
            isArchived() {
                return this.getAdvertArchived();
            },
        },
        methods: {
            ...mapActions(["setPageTitleAction"]),
            async initAdvertView(){

                log(["Init advert view", this.id]);
                await this.loadAdvertByID(this.id);

                // Set Page Title
                let nameStr = this.advertName;
                if(this.isArchived){
                    nameStr += " (" + this.text.archived + ")";
                }
                log(["Initting with name?", nameStr, this.isArchived, this.$route]);

                this.setPageTitleAction({ title : this.$route.meta.pageTitle.replace("%s%", nameStr) });

                await this.addAdvertMetaFormData();
                this.buildAdvertViewForm();
            },
            async addAdvertMetaFormData(){
                log(["Adding template meta form data", this.advertData]);

                this.setFormDataValueByName(this.advertFormData, "name", this.getAdvertName());

                this.setFormDataValueByName(this.advertFormData, "businessUnitID", this.getAdvertBusinessUnitID());
                this.setFormDataOptionsByName(this.advertFormData, "businessUnitID", this.parseBusinessUnitsToSelectOptions(await this.loadBusinessUnits()));

                this.setFormDataValueByName(this.advertFormData, "liveDate", convertDateForInputValue(this.getAdvertLiveDate()));
                this.setFormDataValueByName(this.advertFormData, "expiryDate", convertDateForInputValue(this.getAdvertExpiryDate()));
                this.setFormDataValueByName(this.advertFormData, "isPrivate", this.getAdvertPrivacy());
            },
            buildAdvertViewForm(){
                log(["Building property form", this.advertFormData, this.advertData]);

                this.getAdvertProperties().forEach((value, index) => {
                    log(["Property", index, value[1]]);

                    if(value[1].excluded !== true){
                        this.advertFormData.push(this.buildFormStructureRow(index, value[1], "advertview"));
                    }
                    else
                    {
                        this.advertFormData.push(this.buildDisabledFormStructureRow(index, value[1]));
                    }
                });

                log(["Refresh form data", this.$refs['advertForm']]);
                this.$refs['advertForm'].refreshFormData()
            },
        },
        async mounted() {
            await this.initAdvertView();
        },
    }
</script>
