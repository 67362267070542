<template>
    <div>
        <div class="table-scroller table-shadow">
            <table>
                <thead>
                <tr>
                    <th class="w-5/12">{{text.template_name}}</th>
                    <th class="w-3/12">{{text.business_unit}}</th>
                    <th class="w-2/12">{{text.date_last_modified}}</th>
                    <th class="w-2/12">{{text.actions}}</th>
                </tr>
                </thead>
                <tbody v-if="activeTableData.length > 0">
                <tr v-for="row in activeTableData" :key="row.id">
                    <td><router-link :to="{ name: addSectionPrefix('AdvertsEdit'), params: { id : 0, message : 'template', templateid : row.id } }" :title="text.select + ' ' + row.name" class="default-action">{{row.name}}</router-link></td>
                    <td>{{row.businessUnitName || text.all_business_units}}</td>
                    <td>{{row.lastEdited | formatDate}}</td>
                    <td>
                        <SelectAction :routeName="addSectionPrefix('AdvertsEdit')" :routeParams="{ id : 0, message : 'template', templateid : row.id }" :linkName="row.name" />
                    </td>
                </tr>
                </tbody>
                <tbody v-else>
                <tr>
                    <td colspan="4">{{text.no_results}}</td>
                </tr>
                </tbody>
            </table>
        </div>
        <Pagination v-if="activeTablePagination.totalPages > 0" :pagination="activeTablePagination" @go-previous="refreshActiveTemplatesList" @go-next="refreshActiveTemplatesList" />
    </div>
</template>

<script>
    import lang from "@/shared/lang";

    import {formatContentMixin} from "@/views/mixins/formatContent";
    import {templateActionsMixin} from "@/views/mixins/templateActions";
    import {advertActionsMixin} from "@/views/mixins/advertActions";

    import Pagination from "@/components/pagination";
    import SelectAction from "@/components/actionlinks/select";

    export default {
        name: 'AdvertsNew',
        components: {Pagination, SelectAction},
        mixins: [formatContentMixin, templateActionsMixin, advertActionsMixin],
        data() {
            return {
                activeTableData: [],
                activeTablePagination: [],
            }
        },
        computed: {
            text() {
                return lang;
            },
        },
        methods: {
            async refreshActiveTemplatesList(page = 1) {
                await this.loadActiveTemplatesList(page);
            }
        },
        async mounted() {
            this.refreshActiveTemplatesList(1);
        },
    }
</script>
