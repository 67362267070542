<template>
    <div>
        <PreventUnload :when="shouldPreventNavigation" :message="text.unsaved_navigation_confirm" />
        <AdvertPreview
                :publishFormats="getAdvertPublishFormats()"
                :advertID="getAdvertID()"
                :showRefreshButton="editFormHasChanged"
                ref="advertPreview"
                :key="advertPreviewKey"
                v-if="advertData.published"
                @previewsave="onPreviewSave"
        />

        <div class="columns">
            <div class="sidebar-left ">
                <div class="live-status">
                    <h3 class="live-status-title" v-if="advertFormData.length > 0">{{text.status}}: <span class="live-status-text" :class="[{'incomplete' : !advertComplete}, {'complete' : advertComplete}]">{{advertCompleteText}}</span></h3>

                    <span class="live-status-element-list-title">{{text.jump_to}}:</span>
                    <ul v-if="advertFormData.length > 0" class="live-status-element-list">
                        <li v-for="element in advertFormData" :key="element.id" class="live-status-element-list-item" ><a :href="'#' + element.name" :class="elementLiveStatusClass(element)" @click="triggerScrollToDomElement($event, element.name)">{{element.label}}</a></li>
                    </ul>
                </div>
            </div>
            <div class="content">
                <div class="panel panel-body">
                    <Form v-model="advertFormData" ref="advertForm" @changed="onEditFormChange" />
                </div>
            </div>
        </div>

        <div class="button-group clearfix">
            <div class="button-group-left">
                <router-link :to="{ name: addSectionPrefix('AdvertsNew') }" :title="text.back_previous" class="btn btn-frameless" v-if="isNewAdvert">{{text.back_previous}}</router-link>
            </div>
            <div class="button-group-right">
                <router-link :to="{ name: addSectionPrefix('AdvertsList') }" :title="text.cancel" class="btn btn-tertiary btn-smyspace">{{text.cancel}}</router-link>
                <a href="#" :title="text.save" class="btn btn-secondary btn-smyspace" @click="saveDraft($event, false)" @keyup.enter="saveDraft($event, false)" v-if="!advertData.published" >{{text.save}}</a>
                <a href="#" :title="text.save" class="btn btn-secondary btn-smyspace" @click="save($event, false)" @keyup.enter="save($event, false)" v-if="advertData.published" >{{text.save}}</a>
                <a href="#" :title="text.publish_and_continue" class="btn btn-primary" @click="save($event, true, true)" @keyup.enter="save($event, true, true)" v-if="!getAdvertPublished()">{{text.publish_and_continue}}</a>
                <router-link :to="{ name: addSectionPrefix('AdvertsExport'), params : { 'id' : getAdvertID() } }" :title="text.export" class="btn btn-primary" v-if="!isNewAdvert && getAdvertPublished()">{{text.export}}</router-link>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapActions, mapState} from "vuex";
    import {log, convertDateForInputValue, stripMomentInvalidDateMessage, convertDateFromInputToUTC, localScrollToDomElement} from "@/shared/utils";
    import lang from '@/shared/lang';
    import {MESSAGE_TYPE_SUCCESS, MESSAGE_TYPE_ERROR} from '@/shared/consts';

    import {advertActionsMixin} from "@/views/mixins/advertActions";
    import {formWrapperMixin} from "@/views/mixins/formwrapper";

    import Form from "@/components/form/form";
    import AdvertPreview from "@/components/advertpreview";
    import PreventUnload from 'vue-prevent-unload';

    export default {
        name: 'AdvertsEdit',
        components: {Form, AdvertPreview, PreventUnload},
        mixins: [advertActionsMixin, formWrapperMixin],
        data() {
            return {
                editFormHasChanged: false,
                advertPreviewKey: 0,
                advertFormData: [
                    {
                        id: 100,
                        type: 'text',
                        name: 'name',
                        label: 'Name',
                        placeholder: "",
                        tooltip: "",
                        value: "",
                        validate: {
                            type: 'string',
                            required: true,
                            max: 255,
                            liveValidate: true,
                            liveStatus: false,
                            hasContent:  false
                        },
                    },
                    {
                        id: 200,
                        type: 'select',
                        name: `businessUnitID`,
                        label: 'Publishing departments',
                        placeholder: "",
                        tooltip: "",
                        value: "",
                        validate: {
                            type: 'select',
                            required: true,
                            liveValidate: true,
                            liveStatus: false,
                            hasContent:  false
                        },
                        options: [],
                    },
                    {
                        id: 301,
                        type: 'date',
                        name: `liveDate`,
                        label: 'Live date',
                        placeholder: "",
                        tooltip: "",
                        value: "",
                        validate: {
                            type: 'date',
                            min: 'today',
                            required: true,
                            liveValidate: true,
                            liveStatus: false,
                            hasContent:  false
                        },
                        options: [],
                        classString : "addToColumns",
                    },
                    {
                        id: 302,
                        type: 'date',
                        name: `expiryDate`,
                        label: 'Expiry date',
                        placeholder: "",
                        tooltip: "",
                        value: "",
                        validate: {
                            type: 'date',
                            required: true,
                            compare: {
                                elementName: "liveDate",
                                elementLabel: "Live Date",
                                comparison: ">="
                            },
                            liveValidate: true,
                            liveStatus: false,
                            hasContent:  false
                        },
                        options: [],
                        classString : "addToColumns",
                    },
                    {
                        id: 303,
                        type: 'checkbox',
                        name: `isPrivate`,
                        label: 'Is private',
                        placeholder: "",
                        tooltip: "",
                        value: false,
                        options: [],
                        validate: {
                            liveValidate: true,
                            liveStatus: false,
                            hasContent:  false
                        },
                        classString : "addToColumns",
                    },
                ],
                propertiesList: [],
                propertyFormData: [],
                propertyFormInitialised: false,
            }
        },
        computed: {
            ...mapState(['pageTitle']), // imports from store
            text() {
                return lang;
            },
            id() {
                return this.$route.params.id;
            },
            message() {
                return this.$route.params.message
            },
            templateid() {
                return this.$route.params.templateid;
            },
            isNewAdvert() {
                return this.id == 0 && this.templateid;
            },
            advertComplete() {
                return this.advertFormData.filter(element => {
                    return element.hasOwnProperty("validate") && (element.validate.hasOwnProperty("liveValidate") && element.validate.liveValidate) && (element.validate.hasOwnProperty("liveStatus") && !element.validate.liveStatus);
                }).length < 1;
            },
            advertCompleteText() {
                return this.advertComplete ? this.text.complete : this.text.incomplete;
            },
        },
        methods: {
            ...mapActions(["setPageTitleAction"]),
            async initAdvertEdit(){
                log(["Init advert edit", this.id]);
                if(this.isNewAdvert){
                    log(["Mounted with NEW advert"]);
                    await this.loadAdvertDescriptionByID(this.templateid);

                    // Set Page Title
                    this.setPageTitleAction({ title : this.pageTitle.title.replace("%s%", this.text.add) });
                }
                else
                {
                    log(["Mounted with existing advert", this.id]);
                    await this.loadAdvertByID(this.id);

                    // Set Page Title
                    this.setPageTitleAction({ title : this.pageTitle.title.replace("%s%", this.text.edit) });
                }

                //
                log(["INIT ADVERT EDIT FORM"]);
                await this.addAdvertMetaFormData();
                this.initPropertyRows();
                this.setWatchFormRef("advertForm");

                // TODO: REMOVE HACKYWRAP SOLUTION
                let formWrapElements = document.getElementsByClassName("addToColumns");
                log(["Am I hacky wrapping elements?", formWrapElements]);
                if(formWrapElements.length > 0){
                    let formWrapperElement = document.createElement("div");
                    formWrapperElement.classList.add("form-columns");
                    formWrapElements[0].parentElement.insertBefore(formWrapperElement, formWrapElements[0]);

                    for (let i = 0; i < formWrapElements.length; i++) {
                        formWrapperElement.insertBefore(formWrapElements[i], null);
                    }
                }
                // TODO: ENDS HACKYWRAP SOLUTION
            },
            async addAdvertMetaFormData(){
                log(["Adding template meta form data", this.advertData]);

                this.setFormDataValueByName(this.advertFormData, "name", this.getAdvertName());

                this.setFormDataValueByName(this.advertFormData, "businessUnitID", this.getAdvertBusinessUnitID());
                this.setFormDataOptionsByName(this.advertFormData, "businessUnitID", this.parseBusinessUnitsToSelectOptions(await this.loadBusinessUnits()));

                this.setFormDataValueByName(this.advertFormData, "liveDate", convertDateForInputValue(this.getAdvertLiveDate()));
                this.setFormDataValueByName(this.advertFormData, "expiryDate", convertDateForInputValue(this.getAdvertExpiryDate()));
                this.setFormDataValueByName(this.advertFormData, "isPrivate", this.getAdvertPrivacy());

            },
            initPropertyRows(){
                log(["Intialising property table", this.advertFormData, this.advertData]);

                // remove any fields from the form data that are connected to properties
                this.advertFormData = this.advertFormData.filter(row => row.id > 99);

                this.getAdvertProperties().forEach((value, index) => {
                    log(["Property", index, value[1]]);
                    this.advertFormData.push(this.buildFormStructureRow(index, value[1], "advert"));
                });
            },
            async save(e, navigateNextOnSave = false, publishAdvert = false){
                e.preventDefault();
                this.doSave(navigateNextOnSave, publishAdvert);
            },
            async saveDraft(e, navigateNextOnSave = false, publishAdvert = false){
                e.preventDefault();
                this.doSave(navigateNextOnSave, publishAdvert, true);
            },
            async doSave(navigateNextOnSave = false, publishAdvert = false, draftSave = false){

                log(["Saving new advert set up", navigateNextOnSave, this.advertFormData, draftSave]);

                if( (!draftSave && this.$refs.advertForm.validateForm()) || (draftSave && this.draftValidateForm()) ){
                    log(["Validation OK"]);

                    // loop through properties and try to save them
                    this.advertFormData.forEach(element => {
                        log(["looping through save", element]);

                        switch(element.name) {
                            case "name":
                                this.setAdvertName(this.getFormDataValueByName(this.advertFormData, "name" ));
                                break;
                            case "businessUnitID":
                                this.setAdvertBusinessUnitID(this.getFormDataValueByName(this.advertFormData, "businessUnitID" ));
                                break;
                            case "liveDate":
                                this.setAdvertLiveDate(stripMomentInvalidDateMessage(convertDateFromInputToUTC(this.getFormDataValueByName(this.advertFormData, "liveDate" ))));
                                break;
                            case "expiryDate":
                                this.setAdvertExpiryDate(stripMomentInvalidDateMessage(convertDateFromInputToUTC(this.getFormDataValueByName(this.advertFormData, "expiryDate" ))));
                                break;
                            case "isPrivate":
                                this.setAdvertPrivacy(this.getFormDataValueByName(this.advertFormData, "isPrivate" ));
                                break;
                            default:
                                this.setAdvertPropertyValueByAlias(element.name, this.getFormDataValueByName(this.advertFormData, element.name ));
                        }
                    });

                    //save down to the database
                    let databaseSaveOK = await this.saveAdvert();
                    let advertPublished = false;

                    log(["Saved advert... has ID been passed? ", databaseSaveOK]);

                    let thisAdvertID = this.getAdvertID();

                    if(typeof databaseSaveOK !== "boolean"){
                        thisAdvertID = databaseSaveOK;

                        log(["Save value is not a boolean, so should be an ID, plus we aren't supposed to be navigating to the next screen", thisAdvertID, databaseSaveOK]);

                        if(!navigateNextOnSave){
                            this.$router.replace( { name: this.addSectionPrefix('AdvertsEdit'), params : { id : databaseSaveOK } } );
                        }
                    }

                    if(databaseSaveOK){
                        log(["Does created advert have ID now?", thisAdvertID]);

                        // Reset change watchers
                        this.$refs.advertForm.resetChanged();
                        this.editFormHasChanged = false;

                        if(publishAdvert){
                            if(await this.publishAdvert(thisAdvertID)){
                                advertPublished = true;
                            }
                            else
                            {
                                this.$store.dispatch('addSystemMessageAction', {
                                    'type' : MESSAGE_TYPE_ERROR,
                                    'message' : this.text.advert_publish_error
                                });
                            }
                        }
                    }

                    if(databaseSaveOK && navigateNextOnSave && (!publishAdvert || (publishAdvert && advertPublished))){
                        log(["GO TO NEXT PAGE!", this.addSectionPrefix('AdvertsExport')]);
                        this.$router.push( { name: this.addSectionPrefix('AdvertsExport'), params : { id : thisAdvertID } } );
                    }
                    else if(databaseSaveOK && this.advertData.published)
                    {
                        // trigger refresh of preview
                        this.advertPreviewKey++;
                    }
                }
                else
                {
                  log(["FORM NOT OK"]);
                  this.scrollToFirstError();
                }
            },
            draftValidateForm() {
                return (this.$refs.advertForm.validateFormElement(100) && this.$refs.advertForm.validateFormElement(200));
            },
            elementLiveStatusClass(element) {
                let returnStr = "";
                log("calculating live classes " + element.name);
                if(element.hasOwnProperty("validate") && element.validate.hasOwnProperty("liveStatus")){

                    returnStr += (( (typeof element.validate['required']) === 'function' && element.validate.required() ) ||
                        ((typeof element.validate['required']) !== 'function' && element.validate.required )) ? "required " : " ";
                    returnStr += element.validate.liveStatus ? "valid " : "invalid ";
                    returnStr += element.validate.hasContent ? "has-content " : "no-content ";
                }
                return returnStr;
            },

            onEditFormChange(){
                log("Form has changed");
                this.editFormHasChanged = true;
            },

            onPreviewSave(){
                log("Preview requests save");
                this.doSave(false, false)
            },

            triggerScrollToDomElement(e, id){
                e.preventDefault();
                log(["ScrollTo",id,document.getElementById(id)]);
                if(document.getElementById(id)){
                    localScrollToDomElement(document.getElementById(id));
                }
            },

            showMessage() {
                if(this.message === "iscopy"){
                    this.$store.dispatch('addSystemMessageAction', {
                        'type' : MESSAGE_TYPE_SUCCESS,
                        'message' : this.text.message_advert_copied
                    });
                }
            },
        },
        async mounted() {
            await this.initAdvertEdit();
            this.showMessage();
        },
        watch:{
            id: {
                handler: function() {
                    this.initAdvertEdit();
                },
                deep: true,
            }
        },
    }
</script>
